export default function NP_TM(config) {
	if (!(this instanceof NP_TM)) {
		return new NP_TM(config);
	}
	let defaulConfig = {
		selectorTrigger: '[data-tmid]',
		attrEvent: 'data-tmid',
		additionalEvents: {},
		manualAttachments: (_this) => {}
	};
	this.config = Object.assign({}, defaulConfig, config);

	let defaulEvents = {
		// main-navigation
		'mainNaviOpened': { 'event': 'navigation_geoeffnet' },
		'navpoint1': { 'event': 'navigation-was_bietet_livoya' },
		'navpoint2': { 'event': 'navigation-paket_waehlen' },
		'navpoint3': { 'event': 'navigation-meine_vorteile' },
		'navpoint4': { 'event': 'navigation-so-einfach-gehts' },
		'navpoint5': { 'event': 'navigation-ueberblick-absicherungen' },
		'navpoint6': { 'event': 'navigation-warum-livoya' },
		'navpoint7': { 'event': 'navigation-wer-ist-livoya' },
		'navpoint8': { 'event': 'navigation-haeufige-fragen' },
		'navpoint9': { 'event': 'navigation-kontakt' },
		'navpoint10': { 'event': 'navigation-code-bedingungen' },
		'navpoint11': { 'event': 'navigation-schaden-melden' },
		'btnToLandingpage': { 'event': 'zurueck_zur_landingpage' },

		'page1' : { 'event': 'erreicht_angaben_zum_kredit'},
		'goBackHomePage1': { 'event': 'zur_landingpage_angaben_zum_kredit' },
		'btnLeaveApplication': { 'event': 'abbruch_antrag_ja' },
		'btnStayApplication': { 'event': 'abbruch_antrag_nein' },
		'stickyBenefits': { 'event': 'vorteile_aufgeklappt' },
		'goBackHomePage10': { 'event': 'zur_landingpage_abschluss' },

		// application-step 1
		'checkboxVerifyTruth': { 'event': 'checkbox-wahrheitsgemaesse_angaben' },
		'tooltipCreditSum': { 'event': 'tooltip-kredit_summe' },
		'tooltipRate': { 'event': 'tooltip-monatliche_rate' },
		'tooltipRuntime': { 'event': 'tooltip-laufzeit_kredit' },
		'tooltipMonthCredit': { 'event': 'tooltip-monat_der_kreditauszahlung' },
		'tooltipCheckboxVerify': { 'event': 'tooltip-checkbox_wahrheitserklaerung' },

		// application-step 2
		'radioShortWorkYes': { 'event': 'kurzarbeit_ja' },
		'radioShortWorkNo': { 'event': 'kurzarbeit_nein' },
		'radioReducedWorkYes': { 'event': 'freiberuflich_ja' },
		'radioReducedWorkNo': { 'event': 'freiberuflich_nein' },
		'tooltipWorkgroup': { 'event': 'tooltip-berufsgruppe' },

		// application-step 3
		'tooltipDeath': { 'event': 'tooltip-todesfall' },
		'tooltipIllness': { 'event': 'tooltip-schwere_krankheiten' },
		'tooltipHappeningBonus': { 'event': 'tooltip-ereignis_bonus' },
		'tooltipWorkIncapacity': { 'event': 'tooltip-arbeitsunfaehigkeit' },
		'tooltipUnemployment': { 'event': 'tooltip-arbeitslosigkeit' },
		'linkAddToCartBasis': { 'event': 'link-hinzufuegen-basis-paket' },
		'linkAddToCartKomfort': { 'event': 'link-hinzufuegen-komfort-paket' },
		'linkAddToCartPremium': { 'event': 'link-hinzufuegen-premium-paket' },
		'linkProductDetailBasis': { 'event': 'link-produktdetails-basis-paket' },
		'linkProductDetailKomfort': { 'event': 'link-produktdetails-komfort-paket' },
		'linkProductDetailPremium': { 'event': 'link-produktdetails-premium-paket' },

		// application-step 4
		'linkDataUsageInfo': { 'event': 'link-information_zur_datenverarbeitung' },
		'checkboxPersonalInterests': { 'event': 'checkbox-eigenes_wirtschaftliches_interesse' },
		'tooltipOwnInterest': { 'event': 'tooltip-eigenes_wirtschaftliches_interesse' },

		// application-step 5
		'checkboxPaymentCollect': { 'event': 'checkbox-einzugsermaechtigung' },

		// application-step 6
		'downloadVVI': { 'event': 'vvi_heruntergeladen' },
		'checkboxDocumentsDownloaded': { 'event': 'checkbox-alle_dokumente_sind_heruntergeladen' },
		'tooltipConfidentiality': { 'event': 'tooltip-schweigepflichtentbindung' },

		// application-step 7
		'checkboxConfidentialityAtDeath': { 'event': 'checkbox-schweigepflichtentbindung_im_todesfall' },
		'checkboxConfidentialityAtPayment': { 'event': 'checkbox-schweigepflichtentbindung_im_leistungsfall' },
		'tooltipConfidentialityAtDeath': { 'event': 'tooltip-schweigepflichtentbindung_im_todesfall' },
		'tooltipConfidentialityAtPayment': { 'event': 'tooltip-schweigepflichtentbindung_im_leistungsfall' },

		// application-step 8
		'tooltipEligibilityCode': { 'event': 'tooltip-code' },

		// application-step 9
		'downloadContract': { 'event': 'vertragsunterlagen_heruntergeladen' },

		// subpages
		'btnToCLW': { 'event': 'button_zur_creditlife_website' },
		'linkConditionOfPartizipation': { 'event': 'link-teilnahmebedingungen_herunterladen' },

		// landingpage
		'linkContact': { 'event': 'link-kontakt' },
		'linkPhone': { 'event': 'link-telefon' },
		'toggleIncomeGapDisability': { 'event': 'einkommensluecke_angezeigt_arbeitsunfaehigkeit' },
		'toggleIncomeGapUnemployment': { 'event': 'einkommensluecke_angezeigt_arbeitslosigkeit' },
		'toggleIncomeGapDeath': { 'event': 'einkommensluecke_angezeigt_tod' },
		'sliderChange': { 'event': 'slider_warum_livoya_gewechselt' },

		// links
		'linkCreditLife': { 'event': 'link-creditlife_website' },
		'linkConditionsPartizipation': { 'event': 'link-codebedingungen' },
		'linkConditionsUsage': { 'event': 'link-nutzungsbedingungen' },
		'linkPrivacyPolicy': { 'event': 'link-datenschutz' },
		'linkCookieSettings': { 'event': 'link-cookie-hinweis' },
		'linkImprint': { 'event': 'link-impressum' },

		// faq
		'faq1': { 'event': 'faq-was_ist_livoya' },
		'faq2': { 'event': 'faq-wie_kann_ich_livoya_abschließen' },
		'faq3': { 'event': 'faq-wofuer_benoetige_ich_den_berechtigungscode' },
		'faq4': { 'event': 'faq-was_sichert_livoya_ab' },
		'faq5': { 'event': 'faq-wer_ist_der_versicherer_hinter_livoya' },
		'faq6': { 'event': 'faq-was_kostet_livoya' },
		//'faq7': { 'event': 'faq-wie_kann_ich_kuendigen' },
		'faq8': { 'event': 'faq-wann_endet_der_versicherungsschutz' },
		'faq9': { 'event': 'faq-wie_kann_die_absicherung_gekuendigt_werden' },
		'faq10': { 'event': 'faq-wie_kann_ich_meinen_vertrag_widerrufen' },
		'faq11': { 'event': 'faq-was_ist_im_schadensfall_zu_tun' },
		'faq12': { 'event': 'faq-was_steht_im_kleingedruckten' },
		'faq13': { 'event': 'faq-was_kann_ich_tun_wenn_berechtigungscode_verlegt' },
		'faq14': { 'event': 'faq-werden_gesundheitsdaten_erhoben' },

		// cta
		'ctaLpBonus': { 'event': 'cta-lp_bonus' },
		'ctaLpHead': { 'event': 'cta-lp_head' },
		'ctaLpSmile': { 'event': 'cta-lp_bewahren_sie_ihr_laecheln' },
		'ctaLpWhyLivoya': { 'event': 'cta-lp_warum_livoya' },
		'ctaLpPkgBasis': { 'event': 'cta-lp_paketauswahl_basis' },
		'ctaLpPkgKomfort': { 'event': 'cta-lp_paketauswahl_komfort' },
		'ctaLpPkgPremium': { 'event': 'cta-lp_paketauswahl_premium' },
		'ctaLpAdvantages': { 'event': 'cta-lp_vorteile' },
		'ctaLpRiskSlider': { 'event': 'cta-lp_risiken_slider' },
		'ctaLpThatEasy': { 'event': 'cta-lp_so_einfach_gehts' },
	};

	this.events = Object.assign({}, this.config.additionalEvents, defaulEvents);

	// Click events triggering tag manager events
	let clickEvents = Array.prototype.slice.call(document.querySelectorAll(this.config.selectorTrigger));
	clickEvents.forEach(el => {
		let event = el.getAttribute(this.config.attrEvent);
		if (event) {
			el.addEventListener('click', ev => {
				// event target has to be element, to prevent fake checkboxes from registering multiple times, except for anchor tags
				if (ev.target == el || el.nodeName == "A") {
					this.push(event);
				}
			});
		}
	});

	this.config.manualAttachments(this);
}

/**
 * Pushing events into dataLayer and _mtm respectively.
 *
 * @param	mixed	ids		An array of events or an event as string
 * @param	mixed	val		An optional value to push
 */
NP_TM.prototype.push = function(ids, val) {
	if (!Array.isArray(ids)) {
		ids = [ ids ];
	}

	if (typeof(dataLayer) !== 'undefined') {
		ids.forEach(id => {
			if (typeof(this.events[id]) !== 'undefined') {
				if (!val) {
					dataLayer.push(this.events[id]);
				} else {
					let json = { value: val, event: this.events[id].event };
					dataLayer.push(json);
				}
			}
		});
	}

	if (typeof(_mtm) !== 'undefined') {
		ids.forEach(id => {
			if (typeof(this.events[id]) !== 'undefined') {
				if (!val) {
					_mtm.push(this.events[id]);
				} else {
					let json = { value: val, event: this.events[id].event };
					_mtm.push(json);
				}
			}
		});
	}
};