/**
 * Function to handle Accordions
 * 
 * @config	selectorAccordion 			selector for all accordions
 * @config	selectorItem 				selector for all items inside each accordion
 * @config	cssClassFolded 				class to set on the item when it is folded
 * @config	cssClassUnfolded 			class to set on the item when it is unfolded
 * @config	attrDataAccordionId 		data-attribute to identify the accordions
 * @config	attrDataAccordionItemId 	data-attribute to identify the items inside the accordions
 * @config	eventAccordionUnfold 		name of the show-event dispatched on the modal when it shows up
 * @config	eventAccordionFold 			name of the hide-event dispatched on the modal when it hides
 */
export default function initAccordions ( config ) {
	let defaulConfig = {
		selectorAccordion: '.js-accordion',
		selectorItem: '.js-accordionItem',
		selectorItemToggle: '.js-accordionItemToggle',
		selectorItemFolder: '.js-accordionItemFold',
		selectorItemUnfolder: '.js-accordionItemUnfold',
		cssClassFolded: 'folded',
		cssClassUnfolded: 'unfolded',
		attrDataAccordionId: 'data-accordion-id',
		attrDataAccordionItemId: 'data-accordion-item-id',
		eventAccordionUnfold: 'accordion.unfold',
		eventAccordionFold: 'accordion.fold',
		options: {
			onlyOne: true,
		}
	};
	config = Object.assign({}, defaulConfig, config);

	let allAccordions = Array.prototype.slice.call(document.querySelectorAll(config.selectorAccordion));
	if (allAccordions.length > 0) {
		function accordionUnfold (actionAccordionId, actionItemId) {
			allAccordions.some( accordion => {
				let accordionId = accordion.getAttribute(config.attrDataAccordionId);
				if (accordionId == actionAccordionId) {
					let allItems = Array.prototype.slice.call(accordion.querySelectorAll(config.selectorItem));
					if (allItems.length > 0) {
						allItems.forEach( item => {
							let itemId = item.getAttribute(config.attrDataAccordionItemId);
							if (actionItemId == itemId) {
								item.classList.remove(config.cssClassFolded);
								item.classList.add(config.cssClassUnfolded);
								fireEvent(item, config.eventAccordionUnfold);
							} else {
								if (config.options.onlyOne == true) {
									item.classList.remove(config.cssClassUnfolded);
									item.classList.add(config.cssClassFolded);
								}
							}
						});
					}

					return;
				}
			});
		}
		
		function accordionFold (actionAccordionId, actionItemId) {
			allAccordions.some( accordion => {
				let accordionId = accordion.getAttribute(config.attrDataAccordionId);
				if (accordionId == actionAccordionId) {
					let allItems = Array.prototype.slice.call(accordion.querySelectorAll(config.selectorItem));
					if (allItems.length > 0) {
						allItems.forEach( item => {
							let itemId = item.getAttribute(config.attrDataAccordionItemId);
							if (actionItemId == itemId) {
								item.classList.remove(config.cssClassUnfolded);
								item.classList.add(config.cssClassFolded);
								fireEvent(item, config.eventAccordionFold);
							}
						});
					}

					return;
				}
			});
		}

		function fireEvent(elem, eventName) {
			let event = document.createEvent('Event');
			event.initEvent(eventName, true, true);
			elem.dispatchEvent(event);
		}

		//interaction
		allAccordions.forEach( accordion => {
			let accordionId = accordion.getAttribute(config.attrDataAccordionId);
			let allItems = Array.prototype.slice.call(accordion.querySelectorAll(config.selectorItem));

			allItems.forEach( (item, index) => {
				if (!item.classList.contains(config.cssClassUnfolded) && !item.classList.contains(config.cssClassFolded)) {
					if (index > 0) {
						item.classList.add(config.cssClassFolded);
					} else {
						item.classList.add(config.cssClassUnfolded);
					}
				}
				let itemId = item.getAttribute(config.attrDataAccordionItemId);
				// add click event to close toggle-Buttons that can be unfolders and folders 
				let allCloseTogglers = Array.prototype.slice.call(item.querySelectorAll(config.selectorItemToggle));
				allCloseTogglers.forEach( toggler => {
					toggler.addEventListener('click', e => {
						if (item.classList.contains(config.cssClassUnfolded)) {
							accordionFold(accordionId, itemId);
						} else if (item.classList.contains(config.cssClassFolded)) {
							accordionUnfold(accordionId, itemId);
						}
					});
				});
				// add click event to close fold-Buttons
				let allCloseFolders = Array.prototype.slice.call(item.querySelectorAll(config.selectorItemFolder));
				allCloseFolders.forEach( folder => {
					folder.addEventListener('click', e => { accordionFold(accordionId, itemId);	});
				});
				// add click event to close unfold-Buttons
				let allCloseUnfolders = Array.prototype.slice.call(item.querySelectorAll(config.selectorItemUnfolder));
				allCloseUnfolders.forEach( unfolder => {
					unfolder.addEventListener('click', e => { accordionUnfold(accordionId, itemId);	});
				});
			});
		});

		//can be extended with external triggers
	}
}