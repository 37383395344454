/**
 * Function to handle Modals
 * 
 * @config	selectorModal 		selector for all modals
 * @config	selectorModalShow 	selector for all show-Buttons
 * @config	selectorModalHide 	selector for all hide-Buttons
 * @config	cssClassShow 		class to set on modal when shown
 * @config	cssClassBody 		class to set on body when modal is shown
 * @config	cssClassBackdrop 	class to set on modal if backdrop is activated
 * @config	attrDataModalId 	data-attribute to connect modals with their buttons
 * @config	eventModalShow 		name of the show-event dispatched on the modal when it shows up
 * @config	eventModalHide 		name of the hide-event dispatched on the modal when it hides
 */
export default function initModals ( config ) {
	let defaulConfig = {
		selectorModal: '.js-modal',
		selectorModalContent: '.js-modalContent',
		selectorModalFixedHeader: '.js-modalFixedHeader',
		selectorModalShow: '.js-modalShow',
		selectorModalHide: '.js-modalHide',
		cssClassShow: 'show',
		cssClassBody: 'modal-context',
		cssClassBackdrop: 'backdrop',
		cssClassFixedHead: 'fixed',
		attrDataModalId: 'data-modal-id',
		eventModalShow: 'modal.show',
		eventModalHide: 'modal.hide'
	};
	config = Object.assign({}, defaulConfig, config);
	let allModals = Array.prototype.slice.call(document.querySelectorAll(config.selectorModal));
	if (allModals.length > 0) {
		function fireEvent(elem, eventName) {
			let event = document.createEvent('Event');
			event.initEvent(eventName, true, true);
			elem.dispatchEvent(event);
		}

		function showModal (modal) {
			let modalId = modal.getAttribute(config.attrDataModalId);
			allModals.forEach(elem => {
				// prevents hide event for clicked modal, or we might end up with overlapping events
				if (elem.getAttribute(config.attrDataModalId) !== modalId) {
					hideModal(elem);
				}
			});

			let backdrop = modal.getAttribute('data-backdrop');
			if (backdrop == 'true') {
				modal.classList.add(config.cssClassBackdrop);
			}

			modal.classList.add(config.cssClassShow);
			document.body.classList.add(config.cssClassBody);

			fireEvent(modal, config.eventModalShow);
		}

		function hideModal (modal) {
			if (modal.classList.contains(config.cssClassBackdrop)) {
				modal.classList.remove(config.cssClassBackdrop);
			}

			modal.classList.remove(config.cssClassShow);
			document.body.classList.remove(config.cssClassBody);

			fireEvent(modal, config.eventModalHide);
		}

		allModals.forEach( modal => {
			let modalId = modal.getAttribute(config.attrDataModalId);
			if (modalId != undefined && modalId != null) {
				let allModalShowBtn = Array.prototype.slice.call(document.querySelectorAll(config.selectorModalShow));
				if (allModalShowBtn.length > 0) {
					allModalShowBtn.some( btn => {
						let targetId = btn.getAttribute(config.attrDataModalId);
						if (targetId == modalId) {
							btn.addEventListener('click', function (e) { 
								e.preventDefault();
								e.stopPropagation();
								showModal(modal); 
							});
						}
					});
				}

				let allModalHideBtn = Array.prototype.slice.call(document.querySelectorAll(config.selectorModalHide));
				if (allModalHideBtn.length > 0) {
					allModalHideBtn.some( btn => {
						let targetId = btn.getAttribute(config.attrDataModalId);
						if (targetId == modalId) {
							btn.addEventListener('click', function (e) { 
								e.preventDefault();
								e.stopPropagation();
								hideModal(modal);
							});
						}
					});
				}

				//fixed header in modals
				function fixedHeader() {
					let contentBox = modal.querySelector(config.selectorModalContent);
					let	left = contentBox.offsetLeft;
					let	width = contentBox.offsetWidth;
					let	scrollPos = modal.scrollTop;
					let fixedHead = contentBox.querySelector(config.selectorModalFixedHeader);

					if (fixedHead) {
						if (scrollPos > 25) {
							fixedHead.classList.add(config.cssClassFixedHead);
							fixedHead.setAttribute('style', `left: ${left}px; width: ${width}px;`);

							let fixedHeadHeight = fixedHead.offsetHeight;
							contentBox.setAttribute('style', `padding-top: ${fixedHeadHeight}px;`);
						} else {
							fixedHead.classList.remove(config.cssClassFixedHead);
							fixedHead.removeAttribute('style');
							contentBox.removeAttribute('style');
						}
					}
				}
				modal.addEventListener('scroll', e => { fixedHeader(); });
				window.addEventListener('resize', e => { fixedHeader(); });
			}
		});

		window.addEventListener('click', function (e) {
			let modalId = e.target.getAttribute(config.attrDataModalId);
			if (!!modalId && allModals.some(modal => modal.getAttribute(config.attrDataModalId) == modalId)) {
				hideModal(e.target);
			}
		});
	}
}