require('./components/polyfill');

// jQuery Setup
window.$ = require('jquery');
window.jQuery = window.$;	// alias for Bootstrap

// embedd bootstrap's JS for modal etc.
require('./components/tooltips');
require('./components/stickyInfo');
require('./components/tabbox');

import NP_TM from './components/np-tagmanager';
import initAccordions from './components/accordions';
import initModals from './components/modals';
import Swiper, { Navigation,Pagination } from 'swiper';
import scrollToLinkHandle from "./components/scrollToLink";
import MobileNavigation from "./components/mainNavi";

(function () {
	initAccordions();
	initModals();
	Swiper.use([Navigation, Pagination]);

	//main-navigation
	let mobileNavi = new MobileNavigation({
		selectorNavi: '.js-mainNavi',
		selectorNaviToggle: '.js-mainNaviToggle',
		cssClassBody: 'mainnavi-toggled'
	});

	// main-navi links
	new scrollToLinkHandle({
		beforeScroll: () => {
			let allMainNaviToggles = Array.prototype.slice.call(document.querySelectorAll(mobileNavi.config.selectorNaviToggle));
			let allMainNavis = Array.prototype.slice.call(document.querySelectorAll(mobileNavi.config.selectorNavi));
			let toggles = Array.isArray(allMainNaviToggles) ? allMainNaviToggles : [];
			toggles.forEach(toggle => {
				toggle.classList.remove(mobileNavi.config.cssClassBtn);
			});
			allMainNavis.forEach(mainNavi => {
				mainNavi.classList.remove(mobileNavi.config.cssClassNavi);
			});
			document.querySelector('body').classList.remove(mobileNavi.config.cssClassBody);
		}
	});

	// slider config
	let incomeGapSlider = new Swiper('.js-income-gap-slider', {
		slidesPerView: 'auto',
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			768: {
				allowTouchMove: false,
			},
		},
	});
	let worthItSlider = new Swiper('.js-why-livoya-slider', {
		slidesPerView: 1,
		spaceBetween: 150,
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
	let packageSlider = new Swiper('.js-package-slider', {
		slidesPerView: 'auto',
		initialSlide: 1, // start with second package (Komfort)
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				initialSlide: 0,
				allowTouchMove: true,
			},
			1220: {
				slidesPerView: 'auto',
				allowTouchMove: false,
			},
		},
		on: {
			resize: function() {
				if (window.innerWidth >= 1220) {
					if (this.wrapperEl) {
						let allSlidesWithStyleAttr = Array.prototype.slice.call(this.wrapperEl.querySelectorAll('.swiper-slide[style]'));
						if (allSlidesWithStyleAttr.length > 0) {
							allSlidesWithStyleAttr.forEach(slideWithStyleAttr => slideWithStyleAttr.removeAttribute('style'));
							this.update();
						}
					}
				}
			},
		},
	});

	let allBtnToggleIncomeGapContainer = Array.prototype.slice.call(document.querySelectorAll('.js-btn-toggle-income-gap-container'));

	allBtnToggleIncomeGapContainer.forEach(BtnToggleIncomeGapContainer => {
		let incomeGapToggleBtn = BtnToggleIncomeGapContainer.querySelector('.js-btn-toggle-income-gap');
		let incomeGapToggleLabel = BtnToggleIncomeGapContainer.querySelector('.js-btn-toggle-income-gap-label');
		if (incomeGapToggleBtn && incomeGapToggleLabel) {
			let noIncomeGapIcon = BtnToggleIncomeGapContainer.querySelector('.js-no-income-gap');
			let exampleIncomeGapIcon = BtnToggleIncomeGapContainer.querySelector('.js-example-income-gap');
			incomeGapToggleLabel.addEventListener('click', e => {
				e.preventDefault;
				if (incomeGapToggleBtn.checked) {
					incomeGapToggleBtn.checked = false;
					if (noIncomeGapIcon && exampleIncomeGapIcon) {
						noIncomeGapIcon.classList.remove('hidden');
						exampleIncomeGapIcon.classList.add('hidden');
					}
				} else {
					incomeGapToggleBtn.checked = true;
					if (noIncomeGapIcon && exampleIncomeGapIcon) {
						noIncomeGapIcon.classList.add('hidden');
						exampleIncomeGapIcon.classList.remove('hidden');
					}
				}
			});
		}
	});

	// Google Tag-Manager
	window.tag = new NP_TM({
		manualAttachments: (_this) => {
			worthItSlider.on('slideChange', () => {
				_this.push('sliderChange');
			});
		}
	});

	// activate sticky code-input if it is not in view
	let inviewSelector = '.js-inview';
	let attrInviewIdent = 'data-inview-identifier';
	let allInviews = Array.prototype.slice.call(document.querySelectorAll(inviewSelector));

	/**
	 *
	 * @param 	inview 				HTML-Element		Element to be observed
	 * @param 	allToggles 			Array				List of Elements to be toggled by the observed Element
	 */
	function createObserver(inview, allToggles) {
		let cssClassInviewToggle = 'hidden';

		let observer = new IntersectionObserver(observerAction, { threshold: 0 });

		function observerAction (entries) {
			let visible = getComputedStyle(inview).display !== 'none';
			if ( visible ) {
				entries.forEach(entry => {
					allToggles.forEach(toggle => {
						if(entry.isIntersecting) {
							toggle.classList.add(cssClassInviewToggle);
						} else {
							toggle.classList.remove(cssClassInviewToggle);
						}
					});
				});
			}
		};

		observer.observe(inview);
	}

	allInviews.forEach(inview => {
		let ident = inview.getAttribute(attrInviewIdent);
		let allStickyInputs = Array.prototype.slice.call(document.querySelectorAll('.js-inview-target['+attrInviewIdent+'='+ident+']'));

		createObserver(inview, allStickyInputs);
	});

	// Adding a code to the input box -> we need to check
})();
