/**
 * Function to handle the form fields on the page
 * 
 * @config		selectorNavi				Selector for the navigation
 * @config		selectorNaviToggle			Selector for the toggle button
 * @config		selectorPage				Selector for a page inside the mobile navigation
 * @config		selectorPageSwitchBtn		Selector for a button to switch the page of the mobile navigation
 * @config		attrPageNum					Attribute to identify the pages inside the main navigation
 * @config		attrSwitchtoPageNum			Attribute to tell the switch button to which page to switch
 * @config		attrToggleBtnId				Attribute to set the target to toggle
 * @config		attrNaviId					Attribute to identify the navigation
 * @config		cssClassBody				CSS class to be attached to the body
 * @config		cssClassNavi				CSS class to be attached to the navigation
 * @config		cssClassActive				CSS class to be attached to the currently activated page
 * @config		cssClassBtn					CSS class to be attached to the toggle button for the navigation
 */
export default function MobileNavigation ( config ) {
	if (!(this instanceof MobileNavigation)) {
		return new MobileNavigation(config);
	}
	let defaulConfig = {
		selectorNavi: '.js-mobileNavi',
		selectorNaviToggle: '.js-mobileNaviToggle',
		selectorPage: '.js-paginationPage',
		selectorPageSwitchBtn: '.js-switchPage',
		attrPageNum: 'data-pagination-page',
		attrSwitchtoPageNum: 'data-switchto-page',
		attrToggleBtnId: 'data-toggle-id',
		attrNaviId: 'data-navi-id',
		cssClassBody: 'mobilenavi-toggled',
		cssClassNavi: 'toggled',
		cssClassActive: 'active',
		cssClassBtn: 'toggled'
	};
	this.config = Object.assign({}, defaulConfig, config);

	let allMainNavis = Array.prototype.slice.call(document.querySelectorAll(this.config.selectorNavi));
	allMainNavis.forEach(mainNavi => {
		let allNaviPages = Array.prototype.slice.call(mainNavi.querySelectorAll(this.config.selectorPage));
		allNaviPages.forEach(page => {
			let allSwitchBtns = Array.prototype.slice.call(page.querySelectorAll(this.config.selectorPageSwitchBtn));
			allSwitchBtns.forEach(btn => {
				btn.addEventListener('click', e => {
					e.preventDefault();
					let pageNum = parseInt(btn.getAttribute(this.config.attrSwitchtoPageNum));
					this.togglePage(mainNavi, pageNum);
				});
			});
		});
	});

	let allMainNaviToggles = Array.prototype.slice.call(document.querySelectorAll(this.config.selectorNaviToggle));
	allMainNaviToggles.forEach(toggle => {
		toggle.addEventListener('click', e => {
			e.preventDefault();
			let toggleId = toggle.getAttribute(this.config.attrToggleBtnId);
			let body = document.querySelector('body');
			let mainNavi = document.querySelector(`${this.config.selectorNavi}[${this.config.attrNaviId}="${toggleId}"]`);
			
			this.toggleClass(body, this.config.cssClassBody);
			this.toggleClass(mainNavi, this.config.cssClassNavi);
			this.toggleClass(toggle, this.config.cssClassBtn);
		});
	});
}

/**
 * Function to handle all inputs
 * 
 * @param	elem		HTML-Element	the element to toggle the css class on
 * @param	cssClass	String			CSS class to toggle
 */
MobileNavigation.prototype.toggleClass = function (elem, cssClass) {
	if (elem && cssClass) {
		if (elem.classList.contains(cssClass)) {
			elem.classList.remove(cssClass);
		} else {
			elem.classList.add(cssClass);
		}
	}
}

/**
 * Function to handle all inputs
 * 
 * @param	navi		HTML-Element	the current navi used as context
 * @param	pageNum		int				number of the page to switch to
 */
MobileNavigation.prototype.togglePage = function (navi, pageNum) {
	let allNaviPages = Array.prototype.slice.call(navi.querySelectorAll(this.config.selectorPage));
	allNaviPages.forEach( page => {
		if (page.classList.contains(this.config.cssClassActive)) {
			page.classList.remove(this.config.cssClassActive);
		}
		
		let currPageNum = page.getAttribute(this.config.attrPageNum);
		if (pageNum == currPageNum) {
			page.classList.add(this.config.cssClassActive);
		}
	});
}