let allTabboxes = Array.prototype.slice.call(document.querySelectorAll('.js-tabbox'));

allTabboxes.forEach(tabbox => {
	tabbox.addEventListener('click', function(e) {
		e.preventDefault();

		try {
			// Displaying the effect means a click event on one of three elements, all of them need to fire Tag Manager
			let tagmanager = e.target.hasAttribute('data-tmid') || e.target.parentNode.hasAttribute('data-tmid') || e.target.parentNode.parentNode.hasAttribute('data-tmid') || false;
			if (tagmanager && typeof(tag) !== 'undefined') {
				let event = e.target.getAttribute('data-tmid') || e.target.parentNode.getAttribute('data-tmid') || e.target.parentNode.parentNode.getAttribute('data-tmid') || false;
				if (event) {
					tag.push(event);
				}
			}
		} catch (e) {
			//
		}

		if(e.target.classList.contains('js-tabbox__tab-button') && !e.target.classList.contains('tabbox__tab-button--active')) {
			updateTabbox(e.target, tabbox);
		}
	});
});


const updateTabbox = (clickedElement, tabbox) => {
	// get all tab-buttons and create an array out of the nodeList
	let nodeListTabboxTabButtons = tabbox.querySelectorAll('.js-tabbox__tab-button');
	let listTabBoxButtons = Array.prototype.slice.call(nodeListTabboxTabButtons);

	// get the data-tab-id from the clicked tab-button
	let clickedElementDataTabId = clickedElement.getAttribute('data-tab-id');

	// remove active class from all tab-buttons
	listTabBoxButtons.map(element => element.classList.remove('tabbox__tab-button--active'));

	// add active class to clicked tab-button
	clickedElement.classList.add('tabbox__tab-button--active');

	// get all related tab-content elements and create an array out of the nodeList
	let clickedElementRelatedTabContentElements = tabbox.querySelectorAll('.js-tabbox__tab-content[data-tab-id="' + clickedElementDataTabId + '"]');
	let listClickedElementRelatedTabContentElements = Array.prototype.slice.call(clickedElementRelatedTabContentElements);

	// get all tab-content elements and create an array out of the nodeList
	let nodeListTabboxTabContentElements = tabbox.querySelectorAll('.js-tabbox__tab-content');
	let listTabBoxContentElements = Array.prototype.slice.call(nodeListTabboxTabContentElements);

	// hide all tab-content elements
	listTabBoxContentElements.map(element => element.classList.add('hidden'));

	// show the related tab-content elements
	listClickedElementRelatedTabContentElements.map(element => element.classList.remove('hidden'));
}
