/**
 * Function to handle links that trigger a scroll to an element with a given id
 * 
 * @config		context				HTML-Element		the element that wraps around the scrollToLinks
 * @config		attrLinkTarget		Data-Attribute		data-attribute that includes the selector for the element to scroll to
 * @config		beforeScroll		function			funtion that is called before the scroll happens
 * @config		adjustScroll		function			funtion to adjust the scollheight before the scroll happens
 */
export default function scrollToLinkHandle ( config ) {
	if (!(this instanceof scrollToLinkHandle)) {
		return new scrollToLinkHandle(config);
	}
	let defaulConfig = {
		context: document,
		attrLinkTarget: 'data-scrollto',
		beforeScroll: () => {},
		adjustScroll: (scroll) => { return scroll; }
	};
	this.config = Object.assign({}, defaulConfig, config);
	
	let allScrollToLinks = Array.prototype.slice.call(this.config.context.querySelectorAll(`[${this.config.attrLinkTarget}]`));
	
	allScrollToLinks.forEach(link => {
		link.addEventListener('click', e => { this.handleScroll(link, e ); });
	});
}

/**
 * Function to to scroll to the position of the target of the triggered link
 * 
 * @param	link	HTML-Element	the triggered link
 * @param	event	Event			the event that has triggered this function
 */
scrollToLinkHandle.prototype.handleScroll = function (link, event) {
	event.preventDefault();
	let targetSelector = link.getAttribute(this.config.attrLinkTarget);
	let possibleTargets = Array.prototype.slice.call(document.querySelectorAll(targetSelector));
	
	if (possibleTargets.length > 0) {
		let target = possibleTargets[0];
		this.config.beforeScroll();
		let scrollHeight = this.config.adjustScroll(target.offsetTop);
		window.scrollTo(0, scrollHeight);
	} else {
		return;
	}
}