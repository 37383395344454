import tippy, { sticky } from 'tippy.js';
import {hideAll} from 'tippy.js';

// Tooltip config
let allTooltips = Array.prototype.slice.call(document.querySelectorAll('.js-tooltipBadge'));
allTooltips.forEach(tooltip => {
	let tooltipId = tooltip.getAttribute('data-tooltip-target-id');
	let tooltipContainer = document.querySelector(`.js-tooltip[data-tooltip-id="${tooltipId}"]`);
	if(tooltipContainer !== null && tooltipContainer !== undefined) {
		tippy(tooltip, {
			content: tooltipContainer.innerHTML,
			placement: 'top-end',
			flip: true,
			flipOnUpdate: true,
			flipBehavior: ['left', 'right', 'top'],
			maxWidth: 350,
			arrow: false,
			hideOnClick: true,
			trigger: 'click',
		});
	} else {
		tooltip.setAttribute('style', 'display: none;');
	}
});