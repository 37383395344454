/*
 * Handling for the sticky info box, because "position: sticky" is not supported by all browsers and does not work if the site ist smaller than the window
 */

let stickyInfoBoxes = Array.prototype.slice.call(document.querySelectorAll('.js-sticky-info-box'));
stickyInfoBoxes.forEach(stickyInfoBox => {
	// sticky info box positioning
	let footer = document.querySelector('footer');
	if (footer) {
		try {
			document.querySelector('body').insertBefore(stickyInfoBox, footer);
		} catch (e) {
			console.error(e);
		}
	}

	// sticky footer box open/close click event
	let stickyInfoBoxHeader = stickyInfoBox.querySelector('.js-sticky-info-box-header');
	if (stickyInfoBoxHeader) {
		stickyInfoBoxHeader.addEventListener('click', () => {
			if (stickyInfoBox.classList.contains('collapsed')) {
				stickyInfoBox.classList.remove('collapsed');
			} else {
				stickyInfoBox.classList.add('collapsed');
			}
		});
	}
});
